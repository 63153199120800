// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";

import { isAdmin, logout } from "services/self";

import { observer } from "mobx-react";
import { Menu, Image, Icon } from "semantic-ui-react";
import Sidebar from "components/Sidebar/Sidebar";
import SidebarHeader from "components/Sidebar/SidebarHeader";
import MenuLink from "components/MenuLink";
import ProviderDirectoryAPI from 'images/ProviderDirectoryAPI.png';

export const SideNav = observer(
  () =>
    isAdmin() && (
      <Sidebar>
        <Image
          size="mini"
          src="/custom-content/nav-logo.png"
          style={{ margin: "16px" }}
        />
        <SidebarHeader style={{ fontSize: 16, lineHeight: '20px' }}>Admin</SidebarHeader>
        <MenuLink to="/admin/dashboard" style={{ fontSize: 15, lineHeight: '20px' }}><span><Icon name='grid layout' /></span> Dashboard</MenuLink>
        <MenuLink to="/admin/provider-directory" style={{ fontSize: 15, lineHeight: '20px' }}>
					<span><Icon name="cloud" /></span> Provider Directory API
        </MenuLink>
				<MenuLink style={{ fontSize: 15, lineHeight: '20px', color: 'rgb(255 255 255 / 0.3)' }}>
					<span><Icon name="wpforms" /></span> Formulary API
        </MenuLink>
				<MenuLink style={{ fontSize: 15, lineHeight: '20px', color: 'rgb(255 255 255 / 0.3)' }}>
					<span><Icon name="calendar alternate outline" /></span> Apointment API
        </MenuLink>
				<MenuLink style={{ fontSize: 15, lineHeight: '20px', color: 'rgb(255 255 255 / 0.3)' }}>
					<span><Icon name="users" /></span> Patient Access API
        </MenuLink>
				<MenuLink style={{ fontSize: 15, lineHeight: '20px', color: 'rgb(255 255 255 / 0.3)' }}>
					<span><Icon name="address book outline" /></span> Encounter API
        </MenuLink>
        <MenuLink to="/admin/configure-directory" style={{ fontSize: 15, lineHeight: '20px' }}><span><Icon name="configure" /></span> Configure Directory</MenuLink>
        {/* <MenuLink to='/admin/apis' style={{ fontSize: 15, lineHeight: '20px' }}>API Management</MenuLink> */}
        <Menu.Item style={{ fontSize: 15, lineHeight: '20px' }}>
          <a href="/developer-portal/apis" target="__blank">
            <Icon name="code" /> Developer Portal
          </a>
        </Menu.Item>
        <MenuLink style={{ fontSize: 15, lineHeight: '20px' }}>
          <span><Icon name="settings" /></span> Accounts
          <Menu.Menu>
            <MenuLink to="/admin/accounts">Users</MenuLink>
            <MenuLink to="/admin/accounts/admins">Admins</MenuLink>
            <MenuLink to="/admin/accounts/invites">Invites</MenuLink>
            {/* <MenuLink to='/admin/accounts/requests'>Requests</MenuLink> */}
          </Menu.Menu>
        </MenuLink>
        <MenuLink onClick={logout} style={{ fontSize: 15, lineHeight: '20px' }}><span><Icon name="log out" /></span> Sign out</MenuLink>
      </Sidebar>
    )
);
