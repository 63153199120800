import React, { Component } from 'react'
import { BrowserRouter as Router, Redirect } from 'react-router-dom'
import { Helmet } from "react-helmet";

import { ApiManagement, SideNav } from './'
import { AdminRoute } from './../../'
import PageWithSidebar from 'components/PageWithSidebar'

import RegisteredAccounts from 'pages/Admin/Accounts/RegisteredAccounts'
import AdminAccounts from 'pages/Admin/Accounts/AdminAccounts'
import PendingInvites from 'pages/Admin/Accounts/PendingInvites'
import ProviderDirectory from './ProviderDirectory/ProviderDirectory'
import ConfigureDirectory from './Configure Directory/ConfigureDirectory'
import Dashboard from 'pages/Dashboard'
// import PendingRequests from 'pages/Admin/Accounts/PendingRequests'

function RedirectToApiManagement () {
  return <Redirect to='/admin/dashboard' />
}

export class Admin extends Component {
	render() {
		return (
			<Router>
				<PageWithSidebar sidebarContent={<SideNav />}>
					<Helmet>
						<title>Pure4 Management Portal</title>
					</Helmet>
					<>
						<AdminRoute path='/admin/apis' component={ApiManagement} />
						<AdminRoute exact path='/admin/dashboard' component={Dashboard} />
						<AdminRoute exact path='/admin/provider-directory' component={ProviderDirectory} />
						<AdminRoute exact path='/admin/configure-directory' component={ConfigureDirectory} />
						<AdminRoute exact path='/admin/accounts' component={RegisteredAccounts} />
						<AdminRoute exact path='/admin/accounts/admins' component={AdminAccounts} />
						<AdminRoute exact path='/admin/accounts/invites' component={PendingInvites} />
						<AdminRoute exact path='/' component={RedirectToApiManagement} />
						{/* <AdminRoute exact path='/admin/accounts/requests' component={PendingRequests} /> */}
					</>
				</PageWithSidebar>
			</Router>
		)
	}
}
