import React, { Component } from 'react';
import { Route } from 'react-router-dom'
import { Helmet } from "react-helmet";
import PageWithSidebar from 'components/PageWithSidebar';
import ApisMenu from 'components/ApisMenu';
import Dashboard from 'pages/Dashboard';
import ProviderDirectory from 'pages/Admin/ProviderDirectory/ProviderDirectory';

class DeveloperPortal extends Component {
	render() {
		return(
			<PageWithSidebar
          sidebarContent={
            <ApisMenu path={this.props.match} activateFirst={true} />
          }
          SidebarPusherProps={{ className: "swagger-section" }}
        >
					<Helmet>
						<title>H9323 Developer Portal</title>
					</Helmet>
					<>
						<Route path='/developer-portal/dashboard' component={Dashboard} />
            <Route exact path='/developer-portal/apis' component={ProviderDirectory} />
          </>
			</PageWithSidebar>
		);
	}
}

export default DeveloperPortal;
