import SDK from './SDK';
import { API_GATEWAY_URL } from '../constants/app_constants';

const obj = {
	init() {
		return SDK.init({
			baseUrl: `${API_GATEWAY_URL}`,
			accessToken: window.localStorage.getItem('access-token') || null,
		});
	},
};

export default obj;
