export default {
	init(initializedBase) {
		const base = initializedBase;
		return {
			getImportFiles() {
				return base.details('/file');
			},
			uploadFile(formData) {
				return base.create('/file/import', formData, null, 'multipart/form-data');
			},
			reset() {
				return base.create('/reset');
			}
		};
	},
};
