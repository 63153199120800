// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import * as queryString from 'query-string'

// content-fragments
import { loadFragments } from 'services/get-fragments'

// semantic-ui
import 'semantic-ui-css/semantic.css'
import { Admin } from 'pages/Admin'

// components
import AlertPopup from 'components/AlertPopup'
import Feedback from './components/Feedback'

import { isAdmin, isRegistered, isAuthenticated, init, login, logout, getLoginRedirectUrl } from 'services/self'
import { cognitoDomain, cognitoClientId } from './services/api'
import './index.css'
import DeveloperPortal from 'pages/DeveloperPortal/DeveloperPortal'

loadFragments()

// TODO: Feedback should be enabled if
// the following is true && the current
// user is not an administrator
const feedbackEnabled = window.config.feedbackEnabled

export const RegisteredRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      isRegistered()
        ? <Component {...props} />
        : <Redirect to='/' />
    )}
  />
)

export const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest} render={props => (
      isAdmin()
        ? <Component {...props} />
        : <Redirect to='/' />
    )}
  />
)

class App extends React.Component {
  constructor() {
    super()
    init()

    // We are using an S3 redirect rule to prefix the url path with #!
    // This then converts it back to a URL path for React routing
    if (window.location.hash && window.location.hash[1] === '!') {
      const hashRoute = window.location.hash.substring(2)
      window.history.pushState({}, 'home page', hashRoute)
    }
  }

  componentDidMount() {
    function getCognitoUrl(type) {
      const redirectUri = getLoginRedirectUrl()
      return `${cognitoDomain}/${type}?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`
    }
    // console.log("isAuthenticated() ", isAuthenticated());
    if (!isAuthenticated()) {
      window.location = getCognitoUrl('login');
    } else {

    }
  }

  render() {
    return (
      <BrowserRouter>
        <>
          {/* <NavBar /> */}
          {/* <GlobalModal /> */}
          <Switch>
            <Route exact path='/' component={Admin} />
            <Route
              exact
              path='/index.html'
              component={() => {
                const { action } = queryString.parse(window.location.search)
                if (action === 'login') {
                  login()
                } else if (action === 'logout') {
                  logout()
                }
                if (isAdmin()) {
                  return <Redirect to='/' />
                }
                return <Redirect to='/developer-portal/apis' />
              }}
            />
            {/* <Route path='/getting-started' component={GettingStartedWrap} /> */}
            <AdminRoute path='/admin' component={Admin} />
            <Route path="/developer-portal" component={DeveloperPortal} />
            <Route path='/login' render={() => { login(); return <Redirect to='/' /> }} />
            <Route path='/logout' render={() => { logout(); return <Redirect to='/' /> }} />
            <Route component={() => <h2>Page not found</h2>} />
          </Switch>
          {feedbackEnabled && <Feedback />}
          <AlertPopup />
        </>
      </BrowserRouter>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
