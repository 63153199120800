import React, { Component } from "react";
import { Container, Table, Icon, Button, Confirm } from "semantic-ui-react";
import sdk from "data/index";

class ConfigureDirectory extends Component {
	fileInputRef = React.createRef();
	constructor(props) {
		super(props);
		this.sdk = sdk.init();
		this.state = {
			files: [],
			loading: true,
			resetConfirm: {
				open: false,
			}
		}
	}

	componentDidMount() {
		this.fetchFiles();
	}

	async fetchFiles() {
		try {
			const response = await this.sdk.file.getImportFiles();
			if (response.status === 200) {
				const { result: files } = response.data;
				this.setState({
					files,
					loading: false,
				})
			}
		} catch (error) {
			console.log(error);
		}
	}

	fileChange = async (e) => {
		const formData = new FormData();
		formData.append('uploadFile', e.target.files[0]);
		try {
			const response = await this.sdk.file.uploadFile(formData);
			const { data, status } = response;
			if (status === 200 && data.success) {
				this.fetchFiles();
			}
		} catch (error) {
			alert("Error uploading file", error.message)
			console.log(error, "error");
		}
	}

	confirmReset = (e) => {
		this.setState({
			resetConfirm: {
				open: true,
			}
		})
	}

	toggleConfirm(bool) {
		this.setState({
			resetConfirm: {
				open: bool,
			}
		})
	}

	resetDb = async (e) => {
		try {
			const response = await this.sdk.file.reset();
			const { data, status } = response;
			if (status === 200 && data.success) {
				this.fetchFiles();
			}
		} catch (error) {
			console.log(error);
		} finally {
			this.toggleConfirm(false);
		}
	}

	render() {
		const { files, resetConfirm: { open } } = this.state;
		return (
			<div>
				<Container style={{ padding: '40px 16px' }}>
					<div style={{ display: 'flex' }}>
						<h1>Directories Configured</h1>
						<div style={{ flex: 'auto', textAlign: 'right' }}>
							<Button icon onClick={() => this.fetchFiles()}>
								<Icon name='refresh' />
							</Button>
							<Button onClick={() => this.toggleConfirm(true)}>Reset Database</Button>
							<Button primary onClick={() => this.fileInputRef.current.click()}>Import Directory</Button>
							<input
								ref={this.fileInputRef}
								type="file"
								hidden
								onChange={this.fileChange}
							/>
						</div>
					</div>
					<Table celled striped>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>File Name</Table.HeaderCell>
								<Table.HeaderCell>Status</Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{
								files.map((file, index) => (
									<Table.Row key={index}>
										<Table.Cell collapsing>
											<Icon name="file excel outline" /> {file.customerFileName}
										</Table.Cell>
										<Table.Cell>{file.fileStatus}</Table.Cell>
									</Table.Row>
								))
							}
						</Table.Body>
					</Table>
				</Container>
				<Confirm
					open={open}
					header='Reset all data?'
					content='Are you sure you want to reset all data?'
					onCancel={() => this.toggleConfirm(false)}
					onConfirm={this.resetDb}
				/>
			</div>
		);
	}
}

export default ConfigureDirectory;
