// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// store
import { observer } from 'mobx-react'
import { logout } from 'services/self'
import { Icon } from 'semantic-ui-react'

// utilities
import Sidebar from 'components/Sidebar/Sidebar'
import SidebarHeader from 'components/Sidebar/SidebarHeader'
import MenuLink from 'components/MenuLink'

export default observer(function ApisMenu(props) {
	return (
		<Sidebar>
			<SidebarHeader>APIs</SidebarHeader>
			<MenuLink to="/developer-portal/dashboard" style={{ fontSize: 15, lineHeight: '20px' }}><span><Icon name='grid layout' /></span> Dashboard</MenuLink>
			<MenuLink to="/developer-portal/apis" style={{ fontSize: 15, lineHeight: '20px' }}><span><Icon name="cloud" /></span> Provider Directory API</MenuLink>
			<MenuLink style={{ fontSize: 15, lineHeight: '20px', color: 'rgb(255 255 255 / 0.3)' }}>
				<span><Icon name="wpforms" /></span> Formulary API
        </MenuLink>
			<MenuLink style={{ fontSize: 15, lineHeight: '20px', color: 'rgb(255 255 255 / 0.3)' }}>
				<span><Icon name="calendar alternate outline" /></span> Apointment API
        </MenuLink>
			<MenuLink style={{ fontSize: 15, lineHeight: '20px', color: 'rgb(255 255 255 / 0.3)' }}>
				<span><Icon name="users" /></span> Patient Access API
        </MenuLink>
			<MenuLink style={{ fontSize: 15, lineHeight: '20px', color: 'rgb(255 255 255 / 0.3)' }}>
				<span><Icon name="address book outline" /></span> Encounter API
        </MenuLink>
			<MenuLink onClick={logout} style={{ fontSize: 15, lineHeight: '20px' }}><span><Icon name="log out" /></span> Sign out</MenuLink>
		</Sidebar>
	)
})
